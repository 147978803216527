import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import theme from './theme';
import ConnectedPayment from './containers/Payment';
import ConnectedIntroSummary from './containers/IntroSummary';
import ProgressStepper from './components/ProgressStepper/ProgressStepper';
import ConnectedGuestLogin from './containers/GuestLogin';
import { Header, Footer } from './components';
import ConnectedOrderSuccess from './components/OrderSuccess/OrderSuccess';
import ConnectedError from './containers/Error';
import { actions as paymentActions } from './reducers/payment';
import {
  actions as guestActions,
  selectGuestName,
  selectProgressSteps,
  selectShowPasswordPrompt,
} from './reducers/guest';
import { actions as orderActions, selectLocationName } from './reducers/order';
import { selectAppLoading, selectFatalError } from './reducers';
import ooeConstants from './constants';

export class App extends Component {
  componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { hash } = location;
    if (location.pathname !== '/') {
      history.push(`/${hash}`);
    }
    const { getGuestOauthSession, throwFatalError } = this.props;
    getGuestOauthSession();
    setTimeout(() => throwFatalError(), ooeConstants.THIRTY_MINUTES);
  }

  render() {
    const {
      location,
      userName,
      fatalError,
      throwFatalError,
      showSignInPage,
      history,
      stepCompleted,
      progressSteps,
      loading,
    } = this.props;
    const thisPage = window.location.pathname;
    if (fatalError && (thisPage !== ooeConstants.PATH_NAME.ERROR)) {
      throwFatalError();
    }
    return (
      <ThemeProvider theme={theme}>
        <>
          <Header location={location} userName={userName} />
          <ProgressStepper
            history={history}
            guestLogin={showSignInPage}
            stepCompleted={stepCompleted}
            steps={progressSteps}
            loading={loading}
          />
          <Route exact path="/" component={ConnectedIntroSummary} />
          <Route exact path="/guestLogin" component={ConnectedGuestLogin} />
          <Route exact path="/payment" component={ConnectedPayment} />
          <Route exact path="/summary" component={ConnectedIntroSummary} />
          <Route exact path="/success" component={ConnectedOrderSuccess} />
          <Route exact path="/error" component={ConnectedError} />
          <Footer />
        </>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  getGuestOauthSession: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  userName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  fatalError: PropTypes.bool,
  throwFatalError: PropTypes.func.isRequired,
  stepCompleted: PropTypes.func.isRequired,
  showSignInPage: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  progressSteps: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

App.defaultProps = {
  userName: '',
  fatalError: null,
  showSignInPage: false,
  progressSteps: [],
  loading: true,
};

function mapStateToProps(state) {
  return {
    location: selectLocationName(state),
    userName: selectGuestName(state),
    fatalError: selectFatalError(state),
    showSignInPage: selectShowPasswordPrompt(state),
    progressSteps: selectProgressSteps(state),
    loading: selectAppLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...guestActions, ...paymentActions, ...orderActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
