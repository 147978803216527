import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Input } from '@cfacorp/cowponents';

export const DeliveryTipsCustomAmount = props => {
  const { isSelect, onBlurHandler, selectedCustomTipValue } = props;

  return (
    <StyledCustomTipAmount isSelect={isSelect} selectedCustomTipValue={selectedCustomTipValue} className="styled-custom-tip-amount">
      <Input
        type="number"
        color={isSelect || selectedCustomTipValue !== 0 ? '#fff' : '#004F71'}
        height="35px"
        id="customTip"
        p="1px 1px 1px 20px"
        placeholder="Custom Tip"
        onBlur={onBlurHandler}
        width="calc(100% - 10px)"
        m="5px"
        defaultValue={selectedCustomTipValue !== 0 ? selectedCustomTipValue : ''}
        className="custom-amount-input"
      />
    </StyledCustomTipAmount>
  );
};

DeliveryTipsCustomAmount.propTypes = {
  isSelect: PropTypes.bool,
  onBlurHandler: PropTypes.func,
  selectedCustomTipValue: PropTypes.number,
};

DeliveryTipsCustomAmount.defaultProps = {
  isSelect: false,
  onBlurHandler: () => {},
  selectedCustomTipValue: 0,
};

export const StyledCustomTipAmount = styled('div')`
  position: relative;
  ${Input} {
    background: ${props => (props.isSelect || props.selectedCustomTipValue !== 0 ? props.theme.linearGradient : '#fff')};
    border-color: ${props => props.theme.tertiary}
  }
  & input {
    text-align: center;
    border-radius: 24px;
  }
  &::after{
    content: "$";
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    color: ${props => (props.isSelect || props.selectedCustomTipValue !== 0 ? '#fff' : props.theme.tertiary)};
  }
`;

export default DeliveryTipsCustomAmount;
