import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontWeight,
  fontFamily,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
  textAlign,
  lineHeight,
  height,
  alignSelf,
  display,
  flexDirection,
  alignItems,
  width,
  color,
  top,
  right,
  fontSize,
  space,
  opacity,
  minWidth,
  justifyContent,
} from 'styled-system';


class Badge extends React.Component {
  constructor(props) {
    super(props);
    this.state = { badgeClassName: '' };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(prevProps) {
    const { onChange, badgeContent } = this.props;
    if (prevProps.badgeContent !== badgeContent) {
      this.setState({ badgeClassName: 'pulse' });
      setTimeout(() => {
        this.setState({ badgeClassName: '' });
      }, 2000);
      if (onChange) {
        onChange(badgeContent);
      }
    }
  }

  render() {
    const {
      children, badgeContent, className, ...props
    } = this.props;
    const { badgeClassName } = this.state;
    const emptyBadge = badgeContent === 0 || badgeContent === '' || badgeContent === '0';
    return (
      <StyledBadgeWrapper className={className}>
        {emptyBadge ? <StyledBadge opacity={0} />
          : <StyledBadge {...props} className={badgeClassName}>{badgeContent}</StyledBadge>}
        {children}
      </StyledBadgeWrapper>
    );
  }
}

const pulse = keyframes`
  0% { box-shadow: rgba(0, 79, 113, 0.6); }
  70% { box-shadow: 0 0 0 10px rgba(0, 79, 113, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 79, 113, 0); }
`;

const StyledBadgeWrapper = styled.div({
  position: 'relative',
},
height,
display,
flexDirection,
alignItems,
justifyContent);

const StyledBadge = styled.span`
  z-index: 200;
  position: absolute;
  ${top}
  ${right}
  ${height}
  ${fontWeight}
  ${fontFamily}
  ${fontSize}
  ${borders}
  ${borderColor}
  ${borderRadius}
  ${textAlign}
  ${lineHeight}
  ${buttonStyle}
  ${alignSelf}
  ${display}
  ${opacity}
  ${color}
  ${width}
  ${minWidth}
  ${alignItems}
  ${justifyContent}
  ${space}

  &.pulse {
    border-radius: 50%;
    box-shadow: 0 0 0 rgb(0, 79, 113, 0.6);
    animation: ${pulse} 2s infinite;
  }
`;

StyledBadgeWrapper.defaultProps = {
  height: '100%',
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

StyledBadge.defaultProps = {
  alignItems: 'center',
  borderRadius: '50%',
  minWidth: '22px',
  height: '22px',
  bg: 'primary',
  color: 'white',
  fontFamily: 'sans',
  fontSize: '0.8em',
  textAlign: 'center',
  alignSelf: 'flex-end',
  p: '2px',
  display: 'inline-flex',
  justifyContent: 'center',
  top: 0,
  right: '2%',
};

StyledBadgeWrapper.propTypes = {
  ...display.propTypes,
  ...flexDirection.propTypes,
};

StyledBadge.propTypes = {
  ...display.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...borderRadius.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...textAlign.propTypes,
  ...alignSelf.propTypes,
  ...space.propTypes,
  ...top.propTypes,
  ...right.propTypes,
};

Badge.propTypes = {
  ...display.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...borderRadius.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...textAlign.propTypes,
  ...alignSelf.propTypes,
  ...space.propTypes,
  /** Element that the badge wraps */
  children: PropTypes.node.isRequired,
  /** Value displayed inside badge */
  badgeContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Optional function to be called when badge content changes */
  onChange: PropTypes.func,
};

Badge.defaultProps = {
  badgeContent: 0,
};

export default Badge;
