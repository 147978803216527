import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontFamily,
  borders,
  color,
  borderColor,
  borderRadius,
  space,
  fontSize,
  width,
  height,
  themeGet,
  background,
  backgroundSize,
} from 'styled-system';
import Arrows from '../Icon/Icons/arrows.svg';
import { themed } from '../themeHelper';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: false };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { value, defaultValue } = this.props;
    if (value || defaultValue) {
      this.setState({ selected: true });
    }
  }

  onChange(event) {
    const { onChange } = this.props;
    this.setState({
      selected: true,
    });

    if (onChange) {
      onChange(event);
    }
  }

  render() {
    const {
      children, placeholder, onChange, ...props
    } = this.props;
    const { selected } = this.state;
    return (
      <StyledSelect selected={selected} onChange={this.onChange} {...props}>
        <option hidden>{placeholder}</option>
        {children}
      </StyledSelect>
    );
  }
}

export const StyledSelect = styled.select`
  appearance: none;
  outline: none;

  ${background}
  ${backgroundSize}
  ${color}
  ${fontFamily} 
  ${fontSize}
  ${borders}
  ${borderColor}
  ${borderRadius}
  ${space}
  ${height}
  ${width}
  ${themed('Select')}

  /* Add disabled color if no selection or use props color if selected */
  color: ${(props) => {
    const colorFn = props.selected ? themeGet(`colors.${props.color}`, '#5B6770') : themeGet('colors.outline', '#aaaaaa');
    return colorFn(props);
  }};
`;

Select.propTypes = {
  ...fontFamily.PropTypes,
  ...borders.PropTypes,
  ...color.PropTypes,
  ...borderColor.PropTypes,
  ...borderRadius.PropTypes,
  ...space.PropTypes,
  ...fontSize.PropTypes,
  ...width.PropTypes,
  ...height.PropTypes,
  ...themeGet.PropTypes,
  ...background.PropTypes,
  ...backgroundSize.PropTypes,
  /** Text displayed in select before user starts typing */
  placeholder: PropTypes.string,
  /** Initial option selected - placeholder will not show if this is provided */
  value: PropTypes.string,
  /** Initial option selected - placeholder will not show if this is provided */
  defaultValue: PropTypes.string,
  /** Function that is called when the selected option is changed */
  onChange: PropTypes.func,
  /** Options that the user can choose */
  children: PropTypes.node.isRequired,
};

Select.defaultProps = {
  as: 'select',
  fontSize: 2,
  fontFamily: 'sans',
  background: `#fff url(${Arrows}) no-repeat 98%`,
  backgroundSize: '13px 13px',
  p: '0 0 0 10px',
  m: '10px',
  height: '50px',
  width: 'calc(100% - 16px)',
  border: 1,
  borderColor: 'outline',
  borderRadius: 0,
  placeholder: 'Select One',
};

export default Select;
