import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function Radio(props) {
  const {
    className,
    id,
    onChange,
    checked,
    disabled,
  } = props;
  return (
    <div className={className}>
      <input
        className="radio"
        type="radio"
        id={id}
        value={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {/* eslint-disable*/}
      <label htmlFor={id} />
      {/* eslint-enable */}
    </div>
  );
}


const StyledRadio = styled(Radio)`
  float: left;
  margin: 0;
  height: 50px;
  
  & .radio:checked, .radio:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  & .radio:checked + label, .radio:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${props => props.theme.accent};
  }
  & .radio:checked + label:before, .radio:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  & .radio:checked + label:after, .radio:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: linear-gradient(-180deg, rgba(254,67,108,0.99) 0%, rgba(221,0,49,0.99) 49%, #C20726 100%);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  & .radio:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  & .radio:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  & label {
    padding-top: 2px;
    color: ${props => props.theme.text} !important;
  }
`;

Radio.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  id: '',
  checked: false,
  onChange: () => {},
  disabled: false,
};

export default StyledRadio;
