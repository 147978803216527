import React from 'react';
import styled from 'styled-components';
import {
  width, space, textAlign, height, alignSelf, color,
} from 'styled-system';
import LoadingSpinner from './spinner.gif';

function Spinner(props) {
  return (
    <StyledSpinner>
      <StyledImage {...props} src={LoadingSpinner} alt="Loading spinner" />
    </StyledSpinner>
  );
}

const StyledSpinner = styled.div(
  width,
  textAlign,
  height,
  alignSelf,
  color,
);

StyledSpinner.defaultProps = {
  width: '100%',
  textAlign: 'center',
};

const StyledImage = styled.img(
  width,
  space,
);

StyledImage.defaultProps = {
  width: '44px',
  m: '10px 24px 10px 0',
};

Spinner.propTypes = {
  ...width.propTypes,
  ...height.propTypes,
  ...textAlign.propTypes,
  ...alignSelf.propTypes,
  ...color.propTypes,
};

export default Spinner;
