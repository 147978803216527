import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

export const themeHelper = property => props => props.theme[property];

export const themed = themeHelper;


export const themeProvider = component => (
  <ThemeProvider theme={theme}>
    {component}
  </ThemeProvider>
);


export const withTheme = component => (
  <ThemeProvider theme={theme}>
    {component()}
  </ThemeProvider>
);
