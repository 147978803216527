/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ooe from '../../constants';
import Row from '../Row/Row';
import Column from '../Column/Column';
import { formatDate, formatPaper } from '../../util/format';

function EventSummary({
  destination,
  guestDetails,
  eventDetails,
  destinationDetails,
}) {
  const {
    first,
    last,
    email,
    phone,
  } = guestDetails;
  const {
    time,
    guestCount,
    paperGoods,
    specialInstructions,
    date,
  } = eventDetails;
  const {
    companyName,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    zip,
    restaurantPhone,
  } = destinationDetails || '';
  const { DELIVERY } = ooe;
  return (
    <StyledEventSummary>
      <Row>
        <div className="order-intro" data-cy="paymentDate">
        Upon completion of payment, your order will be ready for {destination} on {formatDate(date)}, {time}
        </div>
      </Row>
      <Row>
        <Column xs="12" sm="7" className="column">
          <Row className="small-title">Contact Information</Row>
          <Row data-cy="guestSummaryFullName">{first} {last}</Row>
          <Row data-cy="guestSummaryEmail">{email}</Row>
          <Row data-cy="guestSummaryPhone">{phone}</Row>
        </Column>
        <Column xs="12" sm="5" className="column">
          <Row className="small-title">
            {destination === DELIVERY ? 'Delivery Address' : 'Pickup Location'}
          </Row>
          <Row data-cy="companyName">{companyName}</Row>
          <Row data-cy="guestAddressStreet">{addressLine1}</Row>
          <Row data-cy="guestAddressLineTwo">{addressLine2}</Row>
          <Row data-cy="guestAddressName">{addressLine3}</Row>
          <Row data-cy="guestAddressCityState">{city}, {state} {zip}</Row>
          <Row data-cy="guestSummaryPhone">{restaurantPhone}</Row>
        </Column>
      </Row>
      <Row>
        <Column xs="12" sm="7" className="column">
          <Row className="small-title">Special Instructions</Row>
          <Row data-cy="specialInstructionsSummary">{formatPaper(paperGoods)} {specialInstructions}</Row>
        </Column>
        <Column xs="12" sm="5" className="column">
          <Row className="small-title">Guest count</Row>
          <Row data-cy="guestCountSummary">{guestCount} guests</Row>
        </Column>
      </Row>
    </StyledEventSummary>
  );
}

export const StyledEventSummary = styled.div`
  color: ${props => props.theme.text};
  font-size: 15px;
  letter-spacing: -0.5px;
  line-height: 28px;
  font-weight: 200;
  & .order-intro {
    font-size: 17px;
    font-weight: 400;
    margin: 30px 0;
  }
  & .column {
    text-align: left;
    padding: 0 60px 35px 0;
    box-sizing: border-box;
  }
  & .payment {
    margin-bottom: 10px;
  }
`;

EventSummary.propTypes = {
  eventDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  destination: PropTypes.string.isRequired,
  guestDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  destinationDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  selectedAccount: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])),
};

EventSummary.defaultProps = {
  eventDetails: {},
  destinationDetails: {},
  guestDetails: {},
  selectedAccount: {},
};

export default EventSummary;
