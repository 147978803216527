import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@cfacorp/cowponents';
import logo from '../../assets/CFA_OneLogo_HZ.svg';

function OneInfo({ className }) {
  return (
    <div className={className}>
      <img className="logo" src={logo} alt="Chick-fil-A One Logo" />
      <p className="info">Download the app. Bypass the line. Enjoy Chick-fil-A faster than ever.</p>
      <div className="app-links">
        <a target="_blank" rel="noopener noreferrer" href="https://smart.link/5pqvpha5x54tu">
          <img className="app-store" alt="Download it on the App Store" src="https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg" />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://smart.link/5pqvpha5x54tu">
          <img className="google-play" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" />
        </a>
      </div>
      <a target="_blank" rel="noopener noreferrer" href="https://one.chick-fil-a.com/">
        <Button variant="customer" className="learn-more">Learn more about the app</Button>
      </a>
    </div>
  );
}

OneInfo.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledMainHeader = styled(OneInfo)`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  line-height: 24px;
  position: absolute;
  top: 65px;
  right: 15%;
  & .logo {
    width: 125px;
    padding: 10px;
  }
  & .info {
    max-width: 325px;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    margin: 20px 0;
    line-height: 25px;
  }
  & .app-store {
    display: inline-block;
    overflow: hidden;
    background: url("https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg") no-repeat;
    width: 135px;
    margin: 10px;
    height: 40px;
    background-size: contain;
  }
  & .google-play {
    width: 155px;
  }
  & .learn-more {
    display: block;
    margin: 15px auto;
  }
  
  @media(max-width: 700px) {
    right: 5%;
  }
  
  @media(max-width: 500px) {
    top: 375px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export default StyledMainHeader;
