import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BottomHero from './BottomHero';
import OneInfo from './OneInfo';
import FooterLinks from './FooterLinks';

function Footer({ className }) {
  return (
    <div className={className}>
      <BottomHero />
      <OneInfo />
      <FooterLinks />
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledFooter = styled(Footer)`
  position: relative;
  & a {
    text-decoration: none;
  }
`;

export default StyledFooter;
