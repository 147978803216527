import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import header from '../../assets/header.jpg';

function TopHero({ className, title }) {
  return (
    <div className={className}>
      <div className="wrapper">
        <h1>{title}</h1>
      </div>
    </div>
  );
}

TopHero.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
};

TopHero.defaultProps = {
  title: '',
};

const StyledTopHero = styled(TopHero)`
  font-family: ${props => props.theme.fontFamily};
  background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.25) 100%);
  background-size: cover;
  background-position: center;
  background-image: url(${header});
  color: white;
  height: 320px;
  text-align: center;
  align-items: center;
  display: flex;
  position: relative;
  & .wrapper {
    margin: 0 auto;
    max-width: 1380px;
    padding: 0 30px;
    width: auto;
  }
  & h1 {
    color: #eeefee;
    font-size: 58px;
    letter-spacing: 1px;
    line-height: 130%;
    margin: 0;
    text-shadow: 0px 0px 15px #616161;
  }
  @media(max-width: 500px) {
    height: 200px;
    & h1 {
      font-size: 40px;
    }
  }
`;

export default StyledTopHero;
