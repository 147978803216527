import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const imageUrl = require('./icon-sprite.svg');

function Icon({ className, children }) {
  return (
    <StyledIcon viewBox="0 0 16 16" className={className}>
      <use xlinkHref={`${imageUrl}#${children}`}>this does not exist</use>
    </StyledIcon>
  );
}

const StyledIcon = styled.svg`
  max-width: ${props => `${props.width}px`};
  max-height: ${props => `${props.height}px`};
  margin: ${props => `${props.margin}`};
`;

Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  className: '',
};

StyledIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

StyledIcon.defaultProps = {
  height: '35',
  width: '35',
  margin: '10px',
};

export default Icon;

