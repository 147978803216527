import React from 'react';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Row from '../Row/Row';

function Card({ className, card, expired }) {
  const paymentType = pathOr('payment', ['cardType'], card).toLowerCase();
  return (
    <StyledCard className={`${className}`}>
      <Icon>{paymentType}</Icon>
      <div className="card-details">
        <Row>{paymentType} {card.accountDisplay}</Row>
        <Row className={expired ? 'expired' : ''}>
          Exp. {card.expirationMonth}/{card.expirationYear} {expired ? '(Card Expired)' : ''}
        </Row>
      </div>
    </StyledCard>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  card: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  expired: PropTypes.bool,
};

Card.defaultProps = {
  expired: false,
  className: '',
};

const StyledCard = styled.div`
  display: inline-box;
  text-align: left !important;
  line-height: 17px !important;
  float: left;

  & .card-details {
    font-size: 12px;
    font-weight: 200;
    text-transform: capitalize;
  }
  
  & .expired {
    color: red;
  }
  
  & svg {
    width: 30px;
    margin: 0 15px 0 0;
  }
`;

export default Card;
