import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { SectionHeader, Section } from '@cfacorp/cowponents';
import ooeConstants from '../../constants';
import theme from '../../theme';
import Footer from '../Footer/Footer';
import { notifyBugsnag } from '../../services/bugsnag';
import Icon from '../Icon';

const UnrecoverableError = () => {
  useEffect(() => {
    notifyBugsnag('Unrecoverable error view displayed', {
      context: 'Unrecoverable Error',
      message: 'This exists as a way to analyze and diagnose the path that led a user to the Uncoverable Error screen',
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Section>
          <SectionHeader>Oops!</SectionHeader>
          <h3 style={{ color: theme.text }}>
            <Icon className="icon">error</Icon>{ooeConstants.unrecoverableError()}
          </h3>
        </Section>
        <Footer>
          {`Version ${ooeConstants.APP_VERSION}`}
        </Footer>
      </>
    </ThemeProvider>
  );
};

export default UnrecoverableError;
