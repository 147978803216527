import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontFamily,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  textAlign,
  lineHeight,
  height,
  alignSelf,
  display,
  flexDirection,
  alignItems,
  width,
  color,
  fontSize,
  space,
  opacity,
  minWidth,
  justifyContent,
  position,
  left,
  themeGet,
} from 'styled-system';

function Tooltip(props) {
  const { children, tooltipContent, ...remainingProps } = props;
  return (
    <StyledTooltip {...remainingProps}>
      {children}
      <div className="tooltip">
        {tooltipContent}
      </div>
    </StyledTooltip>
  );
}

const StyledTooltip = styled.div`
${fontFamily}
${borders}
${borderColor}
${borderRadius}
${boxShadow}
${textAlign}
${lineHeight}
${height}
${alignSelf}
${display}
${flexDirection}
${alignItems}
${width}
${color}
${fontSize}
${space}
${opacity}
${minWidth}
${justifyContent}
${position}
${left}
${themeGet}

& .tooltip {
  box-shadow: 0px 1px 22px 2px rgba(214,214,214,1);
  visibility: hidden;
  background-color: white;
  color: #5B6770;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 0px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
}

& :hover {
  cursor: pointer;
}  

& :hover .tooltip {
  visibility: visible;
}
`;

StyledTooltip.defaultProps = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'sans',
  fontSize: 2,
  color: 'textColor',
};

StyledTooltip.propTypes = {
  ...fontFamily.PropTypes,
  ...borders.PropTypes,
  ...borderColor.PropTypes,
  ...borderRadius.PropTypes,
  ...boxShadow.PropTypes,
  ...textAlign.PropTypes,
  ...lineHeight.PropTypes,
  ...height.PropTypes,
  ...alignSelf.PropTypes,
  ...display.PropTypes,
  ...flexDirection.PropTypes,
  ...alignItems.PropTypes,
  ...width.PropTypes,
  ...color.PropTypes,
  ...fontSize.PropTypes,
  ...space.PropTypes,
  ...opacity.PropTypes,
  ...minWidth.PropTypes,
  ...justifyContent.PropTypes,
  ...position.PropTypes,
  ...left.PropTypes,
  ...themeGet.PropTypes,
};

Tooltip.propTypes = {
  /** Visible text or icon over which you can hover to view the tooltip */
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  /** Content that is revealed when you hover over the visible text or icon. */
  tooltipContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
};

export default Tooltip;
