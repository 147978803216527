import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ChickenSpinner } from '@cfacorp/cowponents';

const Overlay = (props) => {
  const { className } = props;
  return (
    <StyledOverlay role="presentation" className={className}>
      <ChickenSpinner className="chicken-spinner" />
    </StyledOverlay>
  );
};

const StyledOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(90, 90, 90, 0.75);
    z-index: 800;
    & .chicken-spinner {
      z-index: 2000;
      margin: 40vh auto !important;
    }
`;

Overlay.propTypes = {
  className: PropTypes.string,
};

Overlay.defaultProps = {
  className: '',
};

export default Overlay;
