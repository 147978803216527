import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Section,
  SectionHeader,
  CreateAccount,
  Error,
} from '../components';
import {
  selectGuestDetails, selectConvertGuestError, actions as guestActions, selectShowPasswordPrompt,
  selectConvertGuestIsLoading, selectAccountConverted,
} from '../reducers/guest';
import { actions as paymentActions } from '../reducers/payment';
import { getHash } from '../util/location';
import { selectPotentialPoints } from '../reducers/order';

export class GuestLogin extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      guestDetails,
      checkoutAsGuest,
      convertGuest,
      showPasswordPrompt,
      history,
      convertGuestError,
      convertGuestIsLoading,
      stepCompleted,
      accountConverted,
      points,
    } = this.props;
    if (!showPasswordPrompt) {
      history.push(`/payment/#id_token=${getHash().id_token}`);
    }
    return (
      <>
        <Section>
          <SectionHeader>
            Create a Chick-fil-A One Account
            <span style={{ fontSize: 11, color: 'gray' }}>&nbsp; (optional)</span>
          </SectionHeader>
          { convertGuestError && <Error text={convertGuestError} /> }
          <CreateAccount
            guestDetails={guestDetails}
            checkoutAsGuest={checkoutAsGuest}
            convertGuest={convertGuest}
            convertGuestIsLoading={convertGuestIsLoading}
            stepCompleted={stepCompleted}
            accountConverted={accountConverted}
            points={points}
          />
        </Section>
      </>
    );
  }
}

GuestLogin.propTypes = {
  guestDetails: PropTypes.objectOf(PropTypes.string).isRequired,
  checkoutAsGuest: PropTypes.func.isRequired,
  convertGuest: PropTypes.func.isRequired,
  convertGuestError: PropTypes.string,
  showPasswordPrompt: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  convertGuestIsLoading: PropTypes.bool,
  stepCompleted: PropTypes.func.isRequired,
  accountConverted: PropTypes.bool,
  points: PropTypes.number.isRequired,
};

GuestLogin.defaultProps = {
  convertGuestIsLoading: false,
  convertGuestError: '',
  accountConverted: false,
};

function mapStateToProps(state) {
  return {
    guestDetails: selectGuestDetails(state),
    showPasswordPrompt: selectShowPasswordPrompt(state),
    convertGuestError: selectConvertGuestError(state),
    convertGuestIsLoading: selectConvertGuestIsLoading(state),
    accountConverted: selectAccountConverted(state),
    points: selectPotentialPoints(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...guestActions, ...paymentActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestLogin);
