import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function AddNewCardModal({
  modalIsOpen,
  closeModal,
}) {
  return (
    <>
      {modalIsOpen && <StyledOverlay />}
      <StyledPaymentFrame isOpen={modalIsOpen}>
        <CloseButton type="button" onClick={closeModal}>
          <CloseText>Close</CloseText>
        </CloseButton>
        <div id="ucom-container" />
      </StyledPaymentFrame>
    </>
  );
}
const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.35);
  overflow: scroll;
`;
const StyledPaymentFrame = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 13px;
  width: 320px;
  max-height: 585px;
  padding: 10px 10px 10px 0;
  top: calc(100vh * 0.1);
  left: 50%;
  right: auto;
  bottom: auto;
  margin-left: -165px;
  z-index: 120;
`;
const CloseButton = styled('button')`
  position: absolute;
  top: 6px;
  right: 6px;
  background: none;
  border: none;
`;
const CloseText = styled('div')`
  font-size: 12px;
  font-family: Apercu, sans-serif;
  color: #7c8891;
`;

AddNewCardModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

AddNewCardModal.defaultProps = {
  modalIsOpen: false,
  closeModal: () => {},
};

export default AddNewCardModal;
